import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import DangerBox from 'components/Motion_Detection/INSTAR_Cloud/Facial_Recognition/DangerBox';
import NavButtons from 'components/Motion_Detection/INSTAR_Cloud/NavButtons';
import ForumBox from 'components/Motion_Detection/INSTAR_Cloud/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR Cloud - Facial Recognition",
  "path": "/Motion_Detection/INSTAR_Cloud/Facial_Recognition/",
  "dateChanged": "2021-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Here you can see all the people captured in your recorded videos. Each person is displayed with their name and a thumbnail of their face.",
  "image": "./MD_SearchThumb_Cloud_Recordings.png",
  "social": "/images/Search/MD_SearchThumb_Cloud_Recordings.webp",
  "toc": "/images/Search/TOC_Icons/TOC_Icons_WebUI_Cloud.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='INSTAR Cloud - Facial Recognition' dateChanged='2021-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can see all the people captured in your recorded videos. Each person is displayed with their name and a thumbnail of their face.' image='/images/Search/MD_SearchThumb_Cloud_Recordings.png' twitter='/images/Search/MD_SearchThumb_Cloud_Recordings.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/INSTAR_Cloud/Personenerkennung/' locationFR='/fr/Motion_Detection/INSTAR_Cloud/Facial_Recognition/' crumbLabel="Face Recognition" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-cloud",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-cloud",
        "aria-label": "instar cloud permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR Cloud`}</h1>
    <h2 {...{
      "id": "facial-recognition",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#facial-recognition",
        "aria-label": "facial recognition permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Facial Recognition`}</h2>
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    {/*
     import PrimaryBox from 'components/Motion_Detection/INSTAR_Cloud/Facial_Recognition/PrimaryBox'
     <PrimaryBox />
     */}
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#facial-recognition"
        }}>{`Facial Recognition`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#activate-the-service"
            }}>{`Activate the Service`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#last-recorded"
            }}>{`Last recorded`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#register-of-persons"
            }}>{`Register of persons`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#advanced-options"
            }}>{`Advanced Options`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "activate-the-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#activate-the-service",
        "aria-label": "activate the service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Activate the Service`}</h3>
    <p>{`To use number plate recognition or person recognition, the respective icon on the camera must be activated (orange).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a5a0ec3c37297d5dce055abc0becf984/ae77d/Face_Recognition_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABxklEQVQoz4WSTWtTQRSG709Qf4Zdd13/RZaBbLIRxEB10Y2LRkEjSP6CG8FGss4uQkGKSkm1tjEY2zQ3uSa5H3Pnfs69eWRGUtSFDhzO8HJ45p1zjjX0U079jKknuXBW2EsPx3EIw9DkxWLBcrlkNpvhuq65z+dzo8dxjFKKPM+vw3o1itn/KHn0QXLvUPDiJEKGgiAIWK/X6KNzWZYm/y+sr1cOR2djBmOb0Q/BxJVkWWZeLooCKSVJkhiw1jZRFiUq/+Xud91KIkkcBiRSEAlf+6HX61Gr1ahWq1QqFer1OoPBgNVqZR7Ls5xL7wIvcQ1Ya0bXX5ZRRCAEQoSsXNc4aTabWJZFo9Fge3vb3LvdruljFEXG+fDbkOOTY2zbNs6ugbpACGGG4HmeAbbbbba2btNqtej3+9zZ2eH1wQG+75s6DbSnNleTCVKGBpb+C/is9ZwbN28ZZ5vodDpmyrqnGzeqhLyAIs9BZRTqL6B2UBQl7w7fsnv/Lk/393iyt8vjBw85Oz83K6PrNSxNM9JgTrYc4viCsZ/hx9mfQJ1lFEPmooIJpCOYfoZPp+gFUpt90w5ViZq9Z/3lJUffJ7wZ54y8lJ/wIIJhMIZV6wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5a0ec3c37297d5dce055abc0becf984/e4706/Face_Recognition_04.avif 230w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/d1af7/Face_Recognition_04.avif 460w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/7f308/Face_Recognition_04.avif 920w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/24329/Face_Recognition_04.avif 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a5a0ec3c37297d5dce055abc0becf984/a0b58/Face_Recognition_04.webp 230w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/bc10c/Face_Recognition_04.webp 460w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/966d8/Face_Recognition_04.webp 920w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/e4604/Face_Recognition_04.webp 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a5a0ec3c37297d5dce055abc0becf984/81c8e/Face_Recognition_04.png 230w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/08a84/Face_Recognition_04.png 460w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/c0255/Face_Recognition_04.png 920w", "/en/static/a5a0ec3c37297d5dce055abc0becf984/ae77d/Face_Recognition_04.png 1111w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a5a0ec3c37297d5dce055abc0becf984/c0255/Face_Recognition_04.png",
              "alt": "INSTAR Cloud - Facial Recognition",
              "title": "INSTAR Cloud - Facial Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "last-recorded",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#last-recorded",
        "aria-label": "last recorded permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Last Recorded`}</h3>
    <p>{`Here you can see all the people captured in your recorded videos. Each person is displayed with their name and a thumbnail of their face. If the face has not been assigned a name, it will be displayed as `}<strong parentName="p">{`Unknown`}</strong>{`. By clicking the `}<strong parentName="p">{`Assign Face`}</strong>{` button next to the preview image, you can tell the Cloud what the person's name is. If a name has been assigned, you can either click on the green thumb to tell the cloud that the recognition was correct, or on the red thumb if the recognition was wrong. You can then assign the correct name.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ad1bded4ecbde3401527a389579509c5/6be49/Face_Recognition_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.347826086956523%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA+klEQVQY0zXMvU7DMBRA4Tx0F1jYWNm68xC8AagqaiQkBqpKtDWkTVzHyXVy/Vf7+qIOfPs5lTh3ekKpx2FC5dIYCS1K2c0zKqW07gFGAJBS6kE75/hfKaW6X5nHer5bmYe10TZG75zzwYcYY0qpEDEzEZVSmDnnHK8ppyvlRETVZvfzvj2st2InJ+eD7nu0lpl9CIjorMv51ofgSykp+OzRpxLzbVc14igOe7H/nmAgosvlIrv2ebn8rDdgzKlp2raFcWya06CUGMOLuA7HmrsPYq4AwBgDANM0pZS88zPi02JxeH0jZmstIs436KztZ/fVe6N+E5yp8B+D0BZTQPbdgAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad1bded4ecbde3401527a389579509c5/e4706/Face_Recognition_01.avif 230w", "/en/static/ad1bded4ecbde3401527a389579509c5/d1af7/Face_Recognition_01.avif 460w", "/en/static/ad1bded4ecbde3401527a389579509c5/7f308/Face_Recognition_01.avif 920w", "/en/static/ad1bded4ecbde3401527a389579509c5/e35d4/Face_Recognition_01.avif 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ad1bded4ecbde3401527a389579509c5/a0b58/Face_Recognition_01.webp 230w", "/en/static/ad1bded4ecbde3401527a389579509c5/bc10c/Face_Recognition_01.webp 460w", "/en/static/ad1bded4ecbde3401527a389579509c5/966d8/Face_Recognition_01.webp 920w", "/en/static/ad1bded4ecbde3401527a389579509c5/26598/Face_Recognition_01.webp 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ad1bded4ecbde3401527a389579509c5/81c8e/Face_Recognition_01.png 230w", "/en/static/ad1bded4ecbde3401527a389579509c5/08a84/Face_Recognition_01.png 460w", "/en/static/ad1bded4ecbde3401527a389579509c5/c0255/Face_Recognition_01.png 920w", "/en/static/ad1bded4ecbde3401527a389579509c5/6be49/Face_Recognition_01.png 1160w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ad1bded4ecbde3401527a389579509c5/c0255/Face_Recognition_01.png",
              "alt": "INSTAR Cloud - Facial Recognition",
              "title": "INSTAR Cloud - Facial Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Since the cloud analyses entire videos, there will naturally be several images of a person. The face recognition takes the best images and uses them for the face assignment. You can display all the images with the button `}<strong parentName="p">{`assign face`}</strong>{`. A maximum of 3 images are displayed.`}</p>
    <p>{`If several people have been recognised in a video, they are displayed next to each other. Especially at the beginning, when the Cloud has not yet seen many faces of a person, mistakes can still happen, for example that two people who look similar are recognised as one person. You can correct this under `}<strong parentName="p">{`assign face`}</strong>{` and split it between 2 people. Over time, the cloud will learn to assign all persons correctly on its own.`}</p>
    <h3 {...{
      "id": "register-of-persons",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#register-of-persons",
        "aria-label": "register of persons permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Register of Persons`}</h3>
    <p>{`Here you can manage known persons, change their names and create new person entries.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/330ad963d6b052946fc76db44f827571/b7936/Face_Recognition_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "19.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAx0lEQVQI102OQU7DMBREc9cuuEBP0Wv0CizKggVbVogFItBISRMaO7ZT5zt27P9rB8USEm830rzRFNeunW5jWBzo2+wDc2gpSrGhtR6GQQgBAIwxKaUaVUpp/aMYtPlq+7K5dgq085Ew+IWIUkrxXy+zRVoMfj7i2zGKsji8yodTt3/+2Z364wes6MEYRFRK+RCIyDlnrd3UvGUN4NigrO7eFh3j33Vb1Zdz006zwxBMluumxgwYwznXeoox5i/pha9PZb/y91/dyt+cwrGPjwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/330ad963d6b052946fc76db44f827571/e4706/Face_Recognition_02.avif 230w", "/en/static/330ad963d6b052946fc76db44f827571/d1af7/Face_Recognition_02.avif 460w", "/en/static/330ad963d6b052946fc76db44f827571/7f308/Face_Recognition_02.avif 920w", "/en/static/330ad963d6b052946fc76db44f827571/bc401/Face_Recognition_02.avif 1155w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/330ad963d6b052946fc76db44f827571/a0b58/Face_Recognition_02.webp 230w", "/en/static/330ad963d6b052946fc76db44f827571/bc10c/Face_Recognition_02.webp 460w", "/en/static/330ad963d6b052946fc76db44f827571/966d8/Face_Recognition_02.webp 920w", "/en/static/330ad963d6b052946fc76db44f827571/e89d6/Face_Recognition_02.webp 1155w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/330ad963d6b052946fc76db44f827571/81c8e/Face_Recognition_02.png 230w", "/en/static/330ad963d6b052946fc76db44f827571/08a84/Face_Recognition_02.png 460w", "/en/static/330ad963d6b052946fc76db44f827571/c0255/Face_Recognition_02.png 920w", "/en/static/330ad963d6b052946fc76db44f827571/b7936/Face_Recognition_02.png 1155w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/330ad963d6b052946fc76db44f827571/c0255/Face_Recognition_02.png",
              "alt": "INSTAR Cloud - Facial Recognition",
              "title": "INSTAR Cloud - Facial Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In addition, the number of times each person was recognised by the automatic face recognition is shown here. Attention: This refers purely to the automatic detections, a manual assignment does not increase the count.`}</p>
    <h3 {...{
      "id": "advanced-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#advanced-options",
        "aria-label": "advanced options permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advanced Options`}</h3>
    <p>{`Here you can manage of which persons you would like to be notified in case of a sighting.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/04784/Face_Recognition_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA9klEQVQY023QzUrEMBAH8L6u4NF38Dn0PRS8e/JgwYWlrNhuJ2YzSTOTpPmSbVkpq7/DQGD+TGYaEOCdl1JOxD0FFTKiAgBj9NAPI4BSSko59L0QQES11nLRCDgeh94Y7b2b59k5ZmJmjjGufb/1yjn8vtu/tR+77lNb1ohSSufcNE1reBVCYOaykRfNzRPcvYjbZ7h/1Uzkvf87xFqrlEobMcaUUtMJffg2ncAvZS1Z78O/YUTMF7XkklPOqbFGW6MnjYGJrAUA51yt9dy0WS+ltH445UKx0JxTLo1eICIRMdE4jqfTCRdXd1ofPaXHlh5aOpj4AxnxkbLF4WfmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d7e8cbadefeb01f1f2e808386780f7f/e4706/Face_Recognition_03.avif 230w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/d1af7/Face_Recognition_03.avif 460w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/7f308/Face_Recognition_03.avif 920w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/85db8/Face_Recognition_03.avif 1174w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4d7e8cbadefeb01f1f2e808386780f7f/a0b58/Face_Recognition_03.webp 230w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/bc10c/Face_Recognition_03.webp 460w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/966d8/Face_Recognition_03.webp 920w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/963af/Face_Recognition_03.webp 1174w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4d7e8cbadefeb01f1f2e808386780f7f/81c8e/Face_Recognition_03.png 230w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/08a84/Face_Recognition_03.png 460w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/c0255/Face_Recognition_03.png 920w", "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/04784/Face_Recognition_03.png 1174w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4d7e8cbadefeb01f1f2e808386780f7f/c0255/Face_Recognition_03.png",
              "alt": "INSTAR Cloud - Facial Recognition",
              "title": "INSTAR Cloud - Facial Recognition",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      